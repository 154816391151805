
.country-creating-body {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  font-family: Inter, sans-serif;
  color: var(--gray-600);
}
